/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

.cursor-pointer {
  cursor: pointer!important;
}
/*search_input*/
#search-box {
  padding: 5px;
}

.search-button{
  position: absolute;
  top: 11px;
  left: 15px;
  border-width: 0px;
  cursor: pointer;
  border-radius: 25px;
  outline: 0;
  background-color: #ffffff00;
  transition: .3s all;
}
.search-button svg{
  width: 15px;
}
.search-input input{
  position: relative;
  margin: 0 auto;
  border-radius: 25px;
  border: 1px solid #D9D9D9;
  padding: 7px 43px;
  color: #333;
  width: 100%;
  outline: none;
  font-size: 13px;
  height: 40px;
}

/*active_Inactive_buttons*/

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
.btn-active,.btn-inactive{
  padding: 5px 10px;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  font-weight: 500;
  background-color: #fff;
}
.btn-active{
  color: #222222;
}
.btn-inactive{
  color: #7a7a7a;
}
.btn-active .dot{
  font-size: 10px;
  transition: all .4s ease-in-out 0s;
}
.active-dot{
  background-color: #49b497;
}
.inactive-dot{
  background-color: #8a8b8c;
}
